import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data: () => ({
    name: "PartClass",
    backSlash: true,
    selectedProject: "",
    selectedBusiness: "",
    projectData: [],
    businessData: [],
    list: [
      { name: "Parent Classes", active: false },
      { name: "Part Classes", active: false },
    ],
    headersParentClass: [
      {
        text: "Status",
        align: "start",
        value: "status",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Parent Class",
        value: "class",
        width: "75%",
        class: "primary customwhite--text",
      },
    ],
    headersPartClass: [
      {
        text: "Status",
        align: "start",
        value: "status",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Part Class",
        value: "class",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Parent Part Class",
        value: "parent_class",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "edit",
        width: "25%",
        class: "primary customwhite--text",
      },
    ],
    parentPartClass: "",
    classPart: [],
    classParent: [],
    mainPage: "",
    subPage: "",
    favouriteList: [],
    showAdd: false,
    pageUrl: "",
    parentClassName: "",
    parentPartClassName: "",
    partClassName: "",
    parentPartClassItems: [],
    selectParentPartClassItems: [],
    partParentClass: false,
    partStatus: true,
    parentSelected: [],
    selectedParentCls: [],
    parentClsSelected: [],
    selectedParent: "",
    saveResetVal: false,
    parentClassDelDialog: false,
    partClassDelDialog: false,
    saveVal: false,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    pass: 5,
    classID: 0,
    classType: "parent",
    parentClassKey: 0,
    activeStatus: true,
    projectSelected: true,
    businessSelected: true,
    changeStatusParent: [],
    changeStatusPart: [],
    partClassEditDialog: false,
    popStatus: true,
    partParentClsDetails: [],
    popupTitleParent: "",
    popupTitlePart: "",
    refresh: true,
    allParentClass: [],
    activeParents: [],
    actionList: [],
    route: "",
    parentValid: false,
    partValid: false,
    max30Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 30 || "Max Length of 30 character"],
  }),

  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },

  mounted() {
    this.getProjectList();
  },

  methods: {
    //Get Projects List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Get Project Selected
    onProjectSelect() {
      this.getBusinessList(this.selectedProject);
      this.projectSelected = false;
      this.partParentClass = false;
      this.classParent = [];
      this.classPart = [];
    },
    //Get Business List
    getBusinessList(selProject) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/business_header?Pass=1&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.businessData = responseData.filter((obj) => obj.ProjKey === selProject);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.businessSelected = false;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Submitting Project and Business Details
    submitProjBus() {
      this.partParentClass = true;
      this.getPartParentClassList(this.selectedBusiness);
    },
    //Get PartClass and ParentClass List
    getPartParentClassList(selBusiness) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/part_parentclass_list?bu_key=${selBusiness}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.body.message == "") {
              this.classParent = [];
              this.classPart = [];
            } else {
              let responseData = JSON.parse(response.data.body.message);
              this.classParent = responseData.PartClass.filter((obj) => obj.type === "parent");
              this.classParent = this.classParent.filter((obj) => obj.class != "");
              this.activeParents = this.classParent;
              this.parentSelected = responseData.ParentClass;
              this.allParentClass = this.classParent.filter((obj) => obj.active === true);
              this.allParentClass.forEach((element) => {
                this.selectParentPartClassItems.push(element.class);
                this.parentPartClassItems.push(element.class);
              });
              this.classPart = responseData.PartClass.filter((obj) => obj.parent_class !== "" && obj.type === "part");
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get PartClass and ParentClass List without Loader
    getPartParentClassListWithoutLoader(selBusiness) {
      this.axios
        .get(`/cp/part_parentclass_list?bu_key=${selBusiness}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.body.message == "") {
              this.classParent = [];
              this.classPart = [];
            } else {
              let responseData = JSON.parse(response.data.body.message);
              this.classParent = responseData.PartClass.filter((obj) => obj.type === "parent");
              this.activeParents = this.classParent;
              this.parentSelected = responseData.ParentClass;
              this.allParentClass = this.classParent.filter((obj) => obj.active === true);
              this.allParentClass.forEach((element) => {
                this.selectParentPartClassItems.push(element.class);
                this.parentPartClassItems.push(element.class);
              });
              this.classPart = responseData.PartClass.filter((obj) => obj.parent_class !== "" && obj.type === "part");
            }
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Adding new Parentt Class to Existing Parent Class table
    parentClassSubmit() {
      if (this.$refs.ParentClassForm[0].validate()) {
        this.pass = 5;
        this.classType = "parent";
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let parentClassObject = {
          User_Id: parseInt(this.userId),
          pass: this.pass,
          proj_key: parseInt(this.selectedProject),
          bu_key: parseInt(this.selectedBusiness),
          Class_id: this.classID,
          Class: this.parentClassName,
          type: this.classType,
          parent_class_key: this.parentClassKey,
          active: this.activeStatus,
        };
        this.axios
          .post("/cp/partclass_create", parentClassObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getPartParentClassList(this.selectedBusiness);
              this.parentClassName = "";
              this.$refs.ParentClassForm[0].resetValidation();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getPartParentClassList(this.selectedBusiness);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
            this.getPartParentClassList(this.selectedBusiness);
            this.parentClassName = "";
          });
      }
    },
    //Adding new Part Class to Existing Part Class table
    partClassSubmit() {
      if (this.$refs.PartClassForm[0].validate()) {
        this.classType = "part";
        this.pass = 5;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.partClassName !== "" && this.partClassName !== undefined && this.partClassName !== null) {
          if (this.selectedParentCls.length !== 0) {
            let partClassObject = {
              User_Id: parseInt(this.userId),
              pass: this.pass,
              proj_key: parseInt(this.selectedProject),
              bu_key: parseInt(this.selectedBusiness),
              Class_id: this.classID,
              Class: this.partClassName,
              type: this.classType,
              parent_class_key: parseInt(this.selectedParentCls[0].parent_class_key),
              active: this.activeStatus,
            };
            this.axios
              .post("/cp/partclass_create", partClassObject)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.partClassName = "";
                  this.selectedParent = "";
                  this.getPartParentClassList(this.selectedBusiness);
                  this.$refs.PartClassForm[0].resetValidation();
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: "Parent Part Class Name is required.",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: "Part Class Name is required.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Edit functionality in PartClass Table
    allowEdit(item) {
      this.partParentClsDetails = [];
      this.partParentClsDetails = item;
      this.partClassEditDialog = true;
      this.popStatus = item.active;
      this.parentPartClassName = item.class;
      this.parentPartClass = item.parent_class;
    },
    //Submitting Edited Parent Part Class
    parentClsSubmit() {
      this.pass = 4;
      this.classType = "part";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let partClassObject = {
        User_Id: parseInt(this.userId),
        pass: this.pass,
        proj_key: parseInt(this.selectedProject),
        bu_key: parseInt(this.selectedBusiness),
        Class_id: this.partParentClsDetails.class_id,
        Class: this.partParentClsDetails.parent_class,
        type: this.partParentClsDetails.type,
        parent_class_key: parseInt(this.partParentClsDetails.parent_class_key),
        active: this.partParentClsDetails.active,
      };
      this.axios
        .post("/cp/partclass_create", partClassObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.partClassEditDialog = false;
            this.parentPartClass = "";
            this.parentClassName = "";
            this.getPartParentClassList(this.selectedBusiness);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.partClassEditDialog = false;
            this.parentPartClass = "";
            this.getPartParentClassList(this.selectedBusiness);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
          this.partClassEditDialog = false;
          this.parentPartClass = "";
          this.getPartParentClassList(this.selectedBusiness);
        });
    },
    //Cancel Parent Part Class Edit
    parentClassCancel() {
      this.partClassEditDialog = false;
      this.popStatus = false;
      this.parentPartClassName = "";
      this.parentPartClass = "";
      this.getPartParentClassListWithoutLoader(this.selectedBusiness);
    },
    //Reset the screen
    resetFunction() {
      this.getProjectList();
      this.parentClassName = "";
      this.partClassName = "";
      this.selectedParent = "";
      this.parentPartClass = "";
      this.selectedParentCls = [];
      this.partParentClass = false;
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.partClassDelDialog = false;
      this.parentClassDelDialog = false;
      this.businessSelected = true;
      this.projectSelected = true;
      this.parentValid = false;
      this.partValid = false;
      this.$refs.ParentClassForm[0].resetValidation();
      this.$refs.PartClassForm[0].resetValidation();
    },
    //Selection of Parent class in both of the V-select
    parentClassSelected(e) {
      this.parentClsSelected = [];
      this.parentClsSelected = this.parentSelected.filter((obj) => obj.parent_class === e);
      this.partParentClsDetails.parent_class = this.parentClsSelected[0].parent_class;
      this.partParentClsDetails.parent_class_key = this.parentClsSelected[0].parent_class_key;
    },
    parentPartSelected(e) {
      this.selectedParentCls = this.parentSelected.filter((obj) => obj.parent_class === e);
    },
    //Switch Changes for Parent
    changeActiveParent(item) {
      if (item.active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.parentClassDelDialog = true;
      this.changeStatusParent = item;
    },
    //Switch Changes for Part
    changeActivePart(item) {
      if (item.active == true) {
        this.popupTitlePart = "Are you sure you want to disable this item?";
      } else if (item.active == false) {
        this.popupTitlePart = "Are you sure you want to enable this item?";
      }
      this.partClassDelDialog = true;
      this.changeStatusPart = item;
    },
    //Ok button click on status change popup for Parent to Delete
    parentClassDel() {
      this.classType = "parent";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let parentClassDelObject = {
        User_Id: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        bu_key: parseInt(this.selectedBusiness),
        Class_id: parseInt(this.changeStatusParent.class_id),
        Class: this.changeStatusParent.class,
        type: this.classType,
        parent_class_key: parseInt(this.changeStatusParent.parent_class_key),
        active: this.changeStatusParent.active,
      };
      this.axios
        .post("/cp/partclass_delete", parentClassDelObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.getPartParentClassList(this.selectedBusiness);
            this.parentClassDelDialog = false;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.getPartParentClassList(this.selectedBusiness);
            this.parentClassDelDialog = false;
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
          this.getPartParentClassList(this.selectedBusiness);
          this.parentClassDelDialog = false;
        });
    },
    //Ok button click on status change popup for Part Class to Delete
    partClassDel() {
      this.classType = "part";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let statusActive = this.activeParents.filter((obj) => obj.class_id === this.changeStatusPart.parent_class_key);
      if (statusActive[0].active !== false) {
        let partClassDelObject = {
          User_Id: parseInt(this.userId),
          proj_key: parseInt(this.selectedProject),
          bu_key: parseInt(this.selectedBusiness),
          Class_id: parseInt(this.changeStatusPart.class_id),
          Class: this.changeStatusPart.parent_class,
          type: this.classType,
          parent_class_key: parseInt(this.changeStatusPart.parent_class_key),
          active: this.changeStatusPart.active,
        };
        this.axios
          .post("/cp/partclass_delete", partClassDelObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getPartParentClassList(this.selectedBusiness);
              this.partClassDelDialog = false;
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getPartParentClassList(this.selectedBusiness);
              this.partClassDelDialog = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
            this.getPartParentClassList(this.selectedBusiness);
            this.partClassDelDialog = false;
          });
      } else {
        this.partClassDelDialog = false;
        this.getPartParentClassList(this.selectedBusiness);
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let Alert = {
          type: "error",
          isShow: true,
          message: "Parent is not active.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Cancel button on status change popup
    onClickCancel() {
      this.parentClassDelDialog = false;
      this.partClassDelDialog = false;
     // this.getPartParentClassListWithoutLoader(this.selectedBusiness);
    },
    //Tab click event
    tabPartParentClass() {
      this.partClassName = "";
      this.selectParentPartClassItems = [];
      this.selectedParent = "";
      this.parentClassName = "";
      this.getPartParentClassList(this.selectedBusiness);
    },
    //Close dialog
    closeDialog() {
      this.partClassEditDialog = false;
    },
    // to set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    // get page Action

    //  on Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    // to add or remove Favourite issue
  },
  components: {
    breadcrumbComp,
  },
};
